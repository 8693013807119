// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-basic-js": () => import("./../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-developers-docs-index-js": () => import("./../src/pages/developers/docs/index.js" /* webpackChunkName: "component---src-pages-developers-docs-index-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-app-js": () => import("./../src/pages/products/app.js" /* webpackChunkName: "component---src-pages-products-app-js" */),
  "component---src-pages-products-capture-js": () => import("./../src/pages/products/capture.js" /* webpackChunkName: "component---src-pages-products-capture-js" */),
  "component---src-pages-products-slack-js": () => import("./../src/pages/products/slack.js" /* webpackChunkName: "component---src-pages-products-slack-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}

